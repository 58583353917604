const API_URL = process.env.REACT_APP_API_URL + "/query";

const refreshToken = async () => {
	if (!localStorage.getItem('refreshToken')) {
		window.location.href = '/login';
	}
	return await fetch(API_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-API-Key': process.env.REACT_APP_API_KEY
		},
		body: JSON.stringify({
			query: `
			mutation Refresh($token: String!) {
				refresh(
					token: $token
				) {
					access
					refresh
				}
			}
			`,
			variables: {
				token: localStorage.getItem('refreshToken'),
			},
		}),
	})
		.then((res) => res.json())
		.then((result) => {
			if (result.data) {
				localStorage.setItem('accessToken', result.data.refresh.access);
				localStorage.setItem('refreshToken', result.data.refresh.refresh);
			} else {
				localStorage.removeItem('accessToken');
				localStorage.removeItem('refreshToken');
			}
		})
}

export default refreshToken;