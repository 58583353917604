import React from 'react'
import Loader from './Loader'

function LoadingPage() {
     return (

        <div style={{ height: "100vh", width: "100%" }} className=" d-flex justify-content-center align-items-center " >
            <Loader className="mx-2" />
            <Loader className="mx-2" />
            <Loader className="mx-2" />
        </div>

    )
}

export default LoadingPage