import React, { lazy, Suspense, useCallback, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ProvideAuth, useAuth } from '../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from '../components/common/Toast';
import LoadingPage from '../components/common/LoadingPages';
import { useLazyQuery } from '@apollo/client';
import { GET_PARTNER_INFORMATION_GQL } from './GqlLayout';
import AppContext from '../context/Context';
import logoConnected from '../assets/img/illustrations/logo_connected.svg';
import logoNotConnected from '../assets/img/illustrations/logo-e-connect.png';

const DashboardLayout = lazy(() => import('./DashboardLayout'));
const DashboardClientLayout = lazy(() => import('./DashboardClientLayout'));
const AuthBasicLayout = lazy(() => import('./AuthBasicLayout'));
const AuthClientLayout = lazy(() => import('./AuthClientLayout'));
const ErrorLayout = lazy(() => import('./ErrorLayout'));
const ViewFullNewsletter = lazy(() => import('../components/dashboard/Newsletters/ViewFullNewsletter'));
const DisableSendMail = lazy(() => import('../components/auth/basic/DisableSendMail'));

const Layout = () => {
  const [getPartnerInfo, { loading }] = useLazyQuery(GET_PARTNER_INFORMATION_GQL);
  const { setLogoConnected, setLogoNotConnected, setName, setHeader, setPrimary, setSecondary } = useContext(AppContext)

  const handleSettings = useCallback(() => {
    let domain = window.location.hostname
    if (domain !== process.env.REACT_APP_PRELYS_DOMAIN) {
      getPartnerInfo({ variables: { domain } }).then(res => {
        if (res?.data?.getPartnerInformation) {
          let info = res.data.getPartnerInformation
          //setItemToStore('logoConnected', info.logo_connected);
          info?.logo_connected && setLogoConnected(info.logo_connected)

          //setItemToStore('logoNotConnected', info.logo_not_connected);
          info?.logo_not_connected && setLogoNotConnected(info.logo_not_connected)

          //setItemToStore('name', info.name);
          info?.name && setName(info.name)

          if (info.colors) {
            let json = JSON.parse(info.colors)
            let h = json.header
            //setItemToStore('header', h);
            setHeader(h)
            let p = json.primary
            //setItemToStore('primary', p);
            setPrimary(p)

            let s = json.secondary
            //setItemToStore('secondary', s);
            setSecondary(s)
          }


        }
      })
    } else {
      setLogoConnected(logoConnected)
      setLogoNotConnected(logoNotConnected)
      setName("Prelys")
      setHeader('#000000')
      setPrimary('#C79D4C')
      setSecondary('#000000')
    }

    // eslint-disable-next-line
  }, [getPartnerInfo])

  useEffect(() => {
    handleSettings()
  }, [handleSettings])
  loading && <LoadingPage />
  return (
    <Suspense fallback={<LoadingPage />}>
      <ProvideAuth>
        <Router fallback={<span />}>
          <Switch>
            <Route path="/disable-send-mail/:role/:id" exact component={DisableSendMail} />
            <Route path="/newsletters/preview/:id/:type/:target" exact component={ViewFullNewsletter} />
            <Route path="/client-auth" component={AuthClientLayout} />
            <Route path="/auth" component={AuthBasicLayout} />
            <Route path="/errors" component={ErrorLayout} />
            <PrivateClientRoute path="/client" component={DashboardClientLayout} />
            <PrivateRoute path="/" component={DashboardLayout} />
          </Switch>
          <ToastContainer closeButton={<CloseButton />} draggable={true} position={toast.POSITION.BOTTOM_CENTER} />
        </Router>
      </ProvideAuth>

    </Suspense>
  );
};

export default Layout;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user } = useAuth()

  if (loggedIn() && !user) {
    return <LoadingPage />
  }

  return (<Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : <Redirect to={{ pathname: "/auth/login", search: `?next=${props.location.pathname}` }} />
    }
  />)
};

const PrivateClientRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user } = useAuth()

  if (loggedIn() && !user) {
    return <LoadingPage />
  }

  return (<Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : <Redirect to={{ pathname: "/client-auth/login", search: `?next=${props.location.pathname}` }} />
    }
  />)
};