import React, { useContext, createContext, useEffect, useState, useCallback } from "react";
import jwt_decode from "jwt-decode";
import { gql, useLazyQuery } from '@apollo/client';
import { clearLocalStorageFilters } from "../helpers/utils";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext);
};

const setAccessToken = token => token && localStorage.setItem('accessToken', token)

const setRefreshToken = token => token && localStorage.setItem('refreshToken', token)

const setParentAccessToken = token => token && localStorage.setItem('parentAccessToken', token)

const setParentRefreshToken = token => token && localStorage.setItem('parentRefreshToken', token)

const getAccessToken = () => localStorage.getItem('accessToken')

const getParentAccessToken = () => localStorage.getItem('parentAccessToken')

const getParentRefreshToken = () => localStorage.getItem('parentRefreshToken')

const clearParentTokens = () => {
	localStorage.removeItem('parentAccessToken')
	localStorage.removeItem('parentRefreshToken')
}

const getRefreshToken = () => localStorage.getItem('refreshToken')

const getOAuthToken = () => sessionStorage.getItem('oAuth')

const getUserIDFromAccessToken = () => {
	let token = getAccessToken()
	try {
		const decoded = jwt_decode(token);
		return decoded.id
	} catch (err) {
		return ""
	}
}

const isTokenExpired = token => {
	try {
		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		localStorage.removeItem("refreshToken")
		localStorage.removeItem("accessToken")
		return false;
	}
};

const loggedIn = () => {
	const token = getAccessToken();
	const refreshToken = getRefreshToken();
	if ((!!token && !isTokenExpired(token)) || (!!refreshToken && !isTokenExpired(refreshToken))) {
		return true
	}
	return false
};

const storeParentAndChildTokens = tokens => {
	if (tokens?.access && tokens?.refresh) {
		setParentAccessToken(getAccessToken())
		setParentRefreshToken(getRefreshToken())
		setAccessToken(tokens.access)
		setRefreshToken(tokens.refresh)
	}
}

const restoreTokens = () => {

	setAccessToken(getParentAccessToken())
	setRefreshToken(getParentRefreshToken())
	clearParentTokens()

}

const storeTokens = tokens => {
	if (tokens?.access) {
		setAccessToken(tokens.access)
	}
	if (tokens?.refresh) {
		setRefreshToken(tokens.refresh)
	}
}

const LOGIN_GQL = gql`
  mutation Login($email: String!, $password: String!,$keeplogin:Boolean!) {
    login(email: $email, password: $password,keeplogin:$keeplogin) {
      access
      refresh
    }
  }
`;
const LOGIN_BORROWER_GQL = gql`
  mutation LoginBorrower($email: String!, $password: String!,$keeplogin:Boolean!) {
    loginBorrower(email: $email, password: $password,keeplogin:$keeplogin) {
      access
      refresh
    }
  }
`;

const LOG_AS_GQL = gql`
  mutation Logas($id: ID!) {
    logas(id: $id) {
      access
      refresh
    }
  }
`;

const EMAIL_EXIST = gql`
  query EmailExist($email: String!) {
    existUser(email: $email)
  }
`;

const EMAIL_EXIST_BORROWER = gql`
  query EmailExistBorrower($email: String!) {
    existBorrower(email: $email)
  }
`;

const CHECK_SEND_EMAIL = gql`
  query CheckSendMail($role:TargetRole!,$id:ID!) {
    checkSendMail(role: $role,id:$id)
  }
`;

const CHECK_EMAIL_EXCLUSION = gql`
  query checkExclusionMail($email:String!) {
    checkExclusionMail(email:$email)
  }
`; 

const UPDATE_SEND_EMAIL = gql`
  mutation UpdateSendMail($role:TargetRole!,$id:ID!,$send_mail:Boolean!) {
    updateSendMail(role: $role,id:$id,send_mail:$send_mail)
  }
`;

const ADD_EXCLUSIONS_MAIL = gql`
  mutation addExclusionsMail($email:String!,$exclusions_type:exclusions_types!) {
    addExclusionsMail(email:$email,exclusions_type:$exclusions_type)
  }
`;


const LOST_PASSWORD = gql`
  mutation LostPassword($email: String!) {
    lostPassword(email: $email)
  }
`;


const LOST_PASSWORD_BORROWER = gql`
  mutation LostPasswordBorrower($email: String!) {
    lostPasswordBorrower(email: $email)
  }
`;

const RECREATE_PASSWORD = gql`
  mutation RecreatePassword($email: String!, $resetCode: String!, $password: String!, $confirm: String!) {
    recreatePassword(data:{
			email: $email,
			resetCode: $resetCode,
			password: $password,
			confirm: $confirm
		})
  }
`;

const RECREATE_PASSWORD_BORROWER = gql`
  mutation RecreatePasswordBorrower($email: String!, $resetCode: String!, $password: String!, $confirm: String!) {
    recreatePasswordBorrower(data:{
			email: $email,
			resetCode: $resetCode,
			password: $password,
			confirm: $confirm
		})
  }
`;


export const GET_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
	  active
      firstname
      lastname
	  role
      id
	  avatar	
	  main_agency{
		role
		agency{
			id
			name
			type
			is_franchise
		}
	  }
	  type
	  partner{
		id
		adviser{
			id
			type
			email
		}
		
	  }
	  third_party_logins{
		count
		logins{
			id
			login
			login_state
			
		}
	  }
	  societies{
		count
	  }		
    }
  }
`;
export const GET_CURRENT_USER_AGENCIES = gql`
  query CurrentUserAgencies {
    currentUser {
	  agencies(offset:0,limit:50,all_agencies:true){
		agencies{
			agency{
				id
				name
				is_franchise
				type
			}
			role
		}
		count
	  }	
    }
  }
`;

export const GET_CURRENT_ASSISTED_USERS = gql`
  query CurrentUserAssisteUsers {
    currentUser {
		assisted_users{
			count
		}	
		}
  }
`;

// Provider hook that creates auth object and handles state
function useProvideAuth() {

	const [loading, setLoading] = useState(false)

	const [getCurrentUser, { loading: loadingData, data, refetch: RefetchCurrentUser }] = useLazyQuery(GET_CURRENT_USER);
	const [getCurrentUserAgencies, { loading: loadingAgencies, data: dataAgencies, refetch: RefetchCurrentUserAgencies }] = useLazyQuery(GET_CURRENT_USER_AGENCIES);
	const [getCurrentUserAssistedUsers, { loading: loadingAssistedUSers, data: dataAssistedUSers, refetch: RefetchCurrentUserAssistedUsers }] = useLazyQuery(GET_CURRENT_ASSISTED_USERS);

	const handleLoading = useCallback(() => {
		setLoading(loadingData || loadingAgencies || loadingAssistedUSers)
	}, [loadingData, loadingAgencies, loadingAssistedUSers])

	useEffect(() => {
		handleLoading()
	}, [handleLoading])

	useEffect(() => {
		const token = getRefreshToken()
		if (!loggedIn() && token) {
			getCurrentUser()
			getCurrentUserAgencies()
			getCurrentUserAssistedUsers()
		}
		// eslint-disable-next-line
	}, [])

	const [user, setUser] = useState(null);
	useEffect(() => {
		if (loggedIn()) {
			getCurrentUser()
			getCurrentUserAgencies()
			getCurrentUserAssistedUsers()
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data?.currentUser?.role !== "") {
			setUser(u => ({ ...u, ...data?.currentUser }))
		}
	}, [data])

	useEffect(() => {
		if (dataAgencies?.currentUser?.agencies) {
			setUser(u => ({ ...u, agencies: dataAgencies?.currentUser?.agencies?.agencies }))
		}
	}, [dataAgencies])


	useEffect(() => {
		if (dataAssistedUSers?.currentUser?.assisted_users) {
			setUser(u => ({ ...u, assisted_users: dataAssistedUSers?.currentUser?.assisted_users }))
		}
	}, [dataAssistedUSers])




	const logout = (path) => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		clearLocalStorageFilters()
		clearParentTokens()
		window.location.href = path;
	}

	// Return the user object and auth methods
	return {
		loadingAuth: loading,
		LOGIN_GQL,
		LOGIN_BORROWER_GQL,
		LOG_AS_GQL,
		EMAIL_EXIST,
		EMAIL_EXIST_BORROWER,
		LOST_PASSWORD,
		LOST_PASSWORD_BORROWER,
		RECREATE_PASSWORD,
		RECREATE_PASSWORD_BORROWER,
		CHECK_SEND_EMAIL,
		CHECK_EMAIL_EXCLUSION,
		UPDATE_SEND_EMAIL,
		ADD_EXCLUSIONS_MAIL,
		user,
		RefetchCurrentUser,
		RefetchCurrentUserAgencies,
		RefetchCurrentUserAssistedUsers,
		storeTokens,
		storeParentAndChildTokens,
		restoreTokens,
		clearParentTokens,
		loggedIn,
		getAccessToken,
		getParentAccessToken,
		getRefreshToken,
		getOAuthToken,
		getCurrentUser,
		getCurrentUserAgencies,
		getUserIDFromAccessToken,
		logout
	};
}