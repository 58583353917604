export const version = '2.10.1';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  openNavbarVertical:true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '€',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'card',
  logoConnected: process.env.REACT_APP_DEFAULT_LOGO_CONNECTED,
  logoNotConnected: process.env.REACT_APP_DEFAULT_LOGO_NOT_CONNECTED,
  name: 'Prelys',
  header: '#000000',
  primary: '#C79D4C',
  secondary: '#000000',
  cguValid:false 


};
const data = { version, navbarBreakPoint, topNavbarBreakpoint, settings }
export default data;
