import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import Spinning from './Spinning';

const Loader = props => {
/*   const [showSpinner, setShowSpinner] = useState(false); 
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  if (!showSpinner) {
    return null;
  } */
  return(
  
  <Row className="flex-center py-5">
    <Col xs="auto">
      <Spinning {...props} noSpin  />
    </Col>
  </Row> 
)};

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
  type: 'grow',
  size: 'lg',
};

export default Loader;

