import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Media,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime, Interval } from 'luxon'
import { all_states, doc_sections, edoc_states, motives, newsletter_status, partner_states, primary_roles, roles, root_primary_roles, root_primary_roles_with_article, segmentations_fiter } from '../assets/data/data';
import { country_codes } from '../assets/data/country_codes';
import { faBan, faCheck } from '@fortawesome/pro-regular-svg-icons';

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) => (key && store.getItem(key) !== "undefined" && store.getItem(key) !== 'null') ? JSON.parse(store.getItem(key)) : defaultValue;
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const clearItemFromStore = (key, store = localStorage) => store.removeItem(key);

export const getStoreSpace = (store = localStorage) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
  if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
  if (endDate && !moment.isMoment(endDate))
    throw new Error(`End date must be a moment object, received ${typeof startDate}`);

  return `${startDate.format('ll')} - ${endDate ? endDate.format('ll') : 'Present'} • ${startDate.from(
    endDate || moment(),
    true
  )}`;
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
      ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(number)) >= 1.0e3
        ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
        : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#CECECE',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#000000',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#CECECE',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#000000',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const acceptFile = (file, acceptedFileTypes) => {
  if (file && acceptedFileTypes) {
    const acceptedFilesArray = Array.isArray(acceptedFileTypes)
      ? acceptedFileTypes
      : acceptedFileTypes.split(',')
    const fileName = file.name || ''
    const mimeType = (file.type || '').toLowerCase()
    const baseMimeType = mimeType.replace(/\/.*$/, '')

    return acceptedFilesArray.some(type => {
      const validType = type.trim().toLowerCase()
      if (validType.charAt(0) === '.') {
        return fileName.toLowerCase().endsWith(validType)
      } else if (validType.endsWith('/*')) {
        // This is something like a image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, '')
      }
      return mimeType === validType
    })
  }
  return true
}

//===============================
// Formater
//===============================
export const emailFormatter = email => <a href={`mailto:${email}`}>{email}</a>;
export const phoneFormatter = phone => <a href={`tel:${phone}`}>{phone}</a>;
export const booleanFormatter = bool => bool ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faBan} className="text-danger" />;

export const imageFormatter = (url, size = 100) => <Media className="align-items-center position-relative">
  <img className="rounded border border-200" src={url} width={size} alt={url} />
</Media>

export const positionFormatter = (value, row, updatePosition) => <>
  <ButtonGroup vertical>
    <Button color="falcon-default" size="sm" onClick={() => updatePosition(row.id, Number(value) - 1)}><FontAwesomeIcon icon="arrow-up" transform="shrink-2" /></Button>
    <Button disabled color="falcon-default" size="sm">{value}</Button>
    <Button color="falcon-default" size="sm" onClick={() => updatePosition(row.id, Number(value) + 1)}><FontAwesomeIcon icon="arrow-down" transform="shrink-2" /></Button>
  </ButtonGroup>
</>

export const timeFormatter = (cell, format) => moment(cell).format(format);

export const getTimePassed = (time) => {
  if(time ==="0001-01-01T00:00:00Z"){
    return "NC"
  }
  const date1 = DateTime.now();
  const date2 = DateTime.fromISO(time);
  const diff = Interval.fromDateTimes(date2, date1);
  const diffMonth = Math.floor(diff.length('month'))


  return diffMonth || 0
}
export const getTimePassed2 = (time) => {
  const date1 = moment({ h: 0, m: 0 });
  const date2 = moment(time);
  const diffMonth = Math.floor(date2.diff(date1, 'months'))
  const diffDay = Math.floor(date2.diff(date1, 'hours') / 24)
  let res = ""
  if (diffMonth > 0) {
    res = `Dans ${diffMonth} mois`
  } else {
    if (diffDay >= 0) {
      if (diffDay === 0) {
        res = `Aujourd'hui`
      } else if (diffDay === 1) {
        res = `Demain`
      } else {
        res = `Dans ${diffDay} jours`
      }
    } else {
      if (diffDay === -1) {
        res = `Hier`
      } else {
        res = `Il y a ${diffDay} jours`
      }
    }

  }

  return res
}

export const isValidTime = (time) => {
  let now = moment()
  let currTime = extractHourMinFromTime(now.format("HH:mm"))
  let targetTime = extractHourMinFromTime(time)

  return targetTime.h > currTime.h || (targetTime.h === currTime.h && targetTime.m > currTime.m)

}

export const extractHourMinFromTime = (time) => {
  if (time.includes(":")) {
    let times = time.split(":")
    let h = parseInt(times[0])
    let m = parseInt(times[1])
    return { h, m }
  }
  return null
}

export const getMinutesPassed = (time) => {
  const date1 = moment().unix();
  const date2 = moment(time).unix();
  const diff = (date1 - date2) / 1000;
  const diffMinutes = Math.floor(diff / 60)


  return diffMinutes || 0
}

export const getSecondsPassed = (time) => {
  const date1 = moment().unix();
  const date2 = moment(time).unix();
  return Math.floor((date2 - date1) / 1000);
}

export const getMonths = () => {
  let M = []
  for (let i = 0; i < 12; i++) {
    M.push({ name: moment.months(i), value: i + 1 })
  }

  return M
}

export const getYears = () => {
  const start_year = 2013
  const curr_year = parseInt(moment().format("YYYY"), 10)
  const diff = curr_year - start_year
  let Y = []
  for (let i = 0; i < diff + 1; i++) {
    Y.push({ name: `${start_year + i}`, value: start_year + i })

  }
  return Y
}

export const getNums = (start, end) => {
  let nums = []
  for (let i = 0; i < end + 1; i++) {
    nums.push({ name: `${start + i}`, value: start + i })

  }
  return nums
}

export const getState = (value) => all_states.find(r => r.value === value)?.name
export const getProspectPartnerState = (value) => partner_states.find(r => r.value === value)?.name
export const getRole = (value) => roles.find(r => r.value === value)?.name
export const getPrimaryRole = (value) => primary_roles.find(r => r.value === value)?.name
export const getRootPrimaryRole = (value) => root_primary_roles.find(r => r.value === value)?.name
export const getRootPrimaryRoleWithArticle = (value) => root_primary_roles_with_article.find(r => r.value === value)?.name
export const getEdocState = (value) => edoc_states.find(r => r.value === value)?.name
export const getEdocSection = (value) => doc_sections.find(r => r.value === value)?.name
export const getEdocMotive = (value) => motives.find(r => r.value === value)?.name
export const getName = (array, value) => array.find(r => r.value === value)?.name




export const getCodeFromCountry = c => {
  if (!c) return "+33"

  let country = c.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  let country_code = country_codes.find(co => (country.includes(co.name) || country.includes(co.translation)))
  if (!country_code) return "+33"
  return country_code.dial_code
}

export const formatNumberWithSeparator = (n, separator = " ") => {
  let nFormatted = ""
  if (n !== "") {
    n = `${n}`
    let fN = `${n}`.split(".")
    let integer = fN[0]
    let decimals = fN[1]

    for (let i = 1; i <= integer.length; i++) {
      let digit = integer[integer.length - i]
      digit = (i % 3 === 0) ? `${separator}${digit}` : digit
      nFormatted = digit + nFormatted
    }
    if (n[n.length - 1] === '.') {
      nFormatted = nFormatted + "."
    }
    if (decimals) {
      nFormatted = nFormatted + "." + decimals
    }
  }

  return nFormatted
}

export const formatPhone = (phone) => {
  if(phone){
    let nums = phone.split("0")

    if (nums.length > 0 && nums[1]) {
      let number = phone.replaceAll(nums[0], "")
      return Array.from(number).map((n, k) => {
        if (k % 2 === 0) return ` ${n}`
        return n
      }).join("")
    }
  }

  return phone

}

export const formatPhoneTel = (phone) => {
  if(phone){
    let nums = phone.split("0")
    if (nums.length > 0 && nums[1]) {
      let number = phone.replaceAll(nums[0], "")
      return nums[0] + number.slice(1)
    }
  }
  return phone

}

export const formatDateWithSeparator = (n, separator = "/") => {
  let nFormatted = Array.from(n).reduce((acc, val) => {
    let n = parseInt(val)

    if (acc.length < 10 && (n || val === '0')) return (acc.length === 2 || acc.length === 5) ? acc + separator + val : acc + val
    return acc
  }, "")
  return nFormatted
}

export const getSegmentation = (value) => segmentations_fiter.find(r => r.value === value)?.name
export const getNewsletterStatus = (value) => newsletter_status.find(r => r.value === value)?.name

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
export const clearLocalStorageFilters = () => {
  clearItemFromStore("directoriesFilter");
  clearItemFromStore("formsFilter");
  clearItemFromStore("newslettersFilter");
  clearItemFromStore("notificationsFilter");
  clearItemFromStore("partnersFilter");
  clearItemFromStore("prospectPartnersFilter");
  clearItemFromStore("prospectCategoryFilter");
  clearItemFromStore("prospectsFilter");
  clearItemFromStore("agenciesFilter");
  clearItemFromStore("usersFilter");
  clearItemFromStore("statsFilter");
}


export const logErrorToServer = async (errorDetails) => {
  const username = process.env.REACT_APP_SQUIRREL_LOG_USERNAME;
  const password = process.env.REACT_APP_SQUIRREL_LOG_PASSWORD;
  const base64Credentials = Buffer.from(`${username}:${password}`).toString('base64');

  try {
    const response = await fetch(process.env.REACT_APP_SQUIRREL_LOG_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`,
      },
      body: JSON.stringify(errorDetails),
    });

    const data = await response.json();
    console.log("Succès de l'envoi du log:", data);
  } catch (error) {
    console.log("Erreur lors de l'envoi du log:", error);
  }
}