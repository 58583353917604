import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import AppContext from '../../context/Context';
import PropTypes from 'prop-types'

const Spinning = ({ invert,noSpin, ...rest }) => {
  const { primary, secondary } = useContext(AppContext)
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  if (!showSpinner && !noSpin) {
    return null;
  }
  return <Spinner {...rest} style={{ color: invert ? secondary : primary }} />
};

Spinning.propTypes = {
  invert: PropTypes.bool
}

Spinning.defaultProps = {
  invert: false,
  noSpin:false
}

export default Spinning;
