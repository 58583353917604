import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import { settings } from './config';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './helpers/utils';
import "./assets/css/fonts.css"
import "./assets/css/custom.css"

const Main = props => {
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', settings.isFluid));
  const [openNavbarVertical,setOpenNavbarVertical] = useState(getItemFromStore('openNavbarVertical', settings.openNavbarVertical));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', settings.isRTL));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', settings.isDark));
  const [isTopNav, setIsTopNav] = useState(getItemFromStore('isTopNav', settings.isTopNav));
  const [isCombo, setIsCombo] = useState(getItemFromStore('isCombo', settings.isCombo));
  const [isVertical, setIsVertical] = useState(getItemFromStore('isVertical', settings.isVertical));
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', settings.isNavbarVerticalCollapsed)
  );
  const [currency, setCurrency] = useState(settings.currency);
  const [showBurgerMenu, setShowBurgerMenu] = useState(settings.showBurgerMenu);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const [cguValid, setCguValid] = useState(getItemFromStore('cguValid', settings.cguValid));
  const [logoConnected, setLogoConnected] = useState(getItemFromStore('logoConnected', settings.logoConnected));
  const [logoNotConnected, setLogoNotConnected] = useState(getItemFromStore('logoNotConnected', settings.logoNotConnected));
  const [name, setName] = useState(getItemFromStore('name', settings.name));
  const [header, setHeader] = useState(getItemFromStore('header', settings.header));
  const [primary, setPrimary] = useState(getItemFromStore('primary', settings.primary));
  const [secondary, setSecondary] = useState(getItemFromStore('secondary', settings.secondary));
  const [modeImitate, setModeImitate] = useState(localStorage.getItem('parentAccessToken') !== null);
  const [navbarStyle, setNavbarStyle] = useState(getItemFromStore('navbarStyle', settings.navbarStyle));

  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);
  const value = {
    isRTL,
    isDark,
    isCombo,
    isFluid,
    openNavbarVertical,
    setIsRTL,
    isTopNav,
    currency,
    setIsDark,
    setIsCombo,
    setIsFluid,
    setOpenNavbarVertical,
    isVertical,
    toggleModal,
    setIsTopNav,
    navbarStyle,
    setCurrency,
    setIsVertical,
    showBurgerMenu,
    setNavbarStyle,
    isOpenSidePanel,
    navbarCollapsed,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    setNavbarCollapsed,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    logoConnected,
    setLogoConnected,
    cguValid,
    setCguValid,
    logoNotConnected,
    setLogoNotConnected,
    name,
    setName,
    header,
    setHeader,
    primary,
    setPrimary,
    secondary,
    setSecondary,
    modeImitate,
    setModeImitate
  };

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  //console.log("LOG =>",getItemFromStore('isFluid', settings.isFluid))

  useEffect(() => {
    setStylesheetMode('isFluid');
    // eslint-disable-next-line
  }, [isFluid]);


  useEffect(() => {
    setStylesheetMode('isRTL');
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);


  useEffect(() => {
    setItemToStore('openNavbarVertical', openNavbarVertical);
    // eslint-disable-next-line
  }, [openNavbarVertical]);

  useEffect(() => {
    setItemToStore('isTopNav', isTopNav);
    // eslint-disable-next-line
  }, [isTopNav]);

  useEffect(() => {
    setItemToStore('isCombo', isCombo);
    // eslint-disable-next-line
  }, [isCombo]);
  useEffect(() => {
    // console.log("isVertical",isVertical)
    setItemToStore('isVertical', isVertical);
    // eslint-disable-next-line
  }, [isVertical]);

  useEffect(() => {
    setItemToStore('navbarStyle', navbarStyle);
    // eslint-disable-next-line
  }, [navbarStyle]);

  useEffect(() => {
    setItemToStore('logoConnected', logoConnected);
    // eslint-disable-next-line
  }, [logoConnected]);

  useEffect(() => {
    setItemToStore('cguValid', cguValid);
    // eslint-disable-next-line
  }, [cguValid]);

  useEffect(() => {
    setItemToStore('logoNotConnected', logoNotConnected);
    // eslint-disable-next-line
  }, [logoNotConnected]);

  useEffect(() => {
    setItemToStore('name', name);
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    setItemToStore('header', header);
    // eslint-disable-next-line
  }, [header]);

  useEffect(() => {
    setItemToStore('primary', primary);
    // eslint-disable-next-line
  }, [primary]);

  useEffect(() => {
    setItemToStore('secondary', secondary);
    // eslint-disable-next-line
  }, [secondary]);



  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
