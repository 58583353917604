import { gql } from '@apollo/client';

export const GET_PARTNER_INFORMATION_GQL = gql`
	query GetPartnerInformation($domain: String!,$id:ID) {
		getPartnerInformation(domain: $domain,id:$id) {
			logo_connected
            logo_not_connected
            name
            colors
		}
	}
	`;